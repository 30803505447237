import type { RowContentElement } from 'behavior/content';
import HeroEffectLayout from './HeroEffectLayout';
import { Media } from 'components/responsive';
import { renderRows } from 'components/containers';

type Props = {
  desktop: RowContentElement[];
  mobile: RowContentElement[] | null;
};

const Page = ({ desktop, mobile }: Props) => (
  <>
    <Media at="xs">
      {renderRows(mobile ?? desktop)}
    </Media>
    <Media greaterThanOrEqual="sm">
      <HeroEffectLayout />
      {renderRows(desktop)}
    </Media>
  </>
);

export default Page;
