import './HeroEffectLayout.module.scss';
import type { ContentPage } from 'behavior/pages/contentPage';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useResponsiveBreakpoints, useLayoutShifter } from 'utils/layout';

const HeroEffectLayout = () => {
  const layoutHeroEffect = useSelector(state => (state.page as ContentPage).layoutHeroEffect);
  const { headerOnTop, headerTextColor } = layoutHeroEffect || {};
  const { topShiftBlockHeight, topFixedElementsHeight } = useLayoutShifter();
  const { md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;
  const isSticky = topFixedElementsHeight - topShiftBlockHeight > 0;

  useEffect(() => {
    const layout = document.getElementById('layout');
    if (layout && headerOnTop)
      layout.dataset.heroLayout = `${isDesktop}`;
  }, [isDesktop, headerOnTop]);

  useEffect(() => {
    const header = document.getElementById('header');
    if (header && headerOnTop)
      header.dataset.heroColored = `${isDesktop && !isSticky && !!headerTextColor}`;
  }, [isSticky, isDesktop, !!headerTextColor, headerOnTop]);

  useEffect(() => {
    const header = document.getElementById('header');
    if (header && headerOnTop)
      header.style.top = isSticky ? '' : topShiftBlockHeight + 'px';
    else
      clearLayout();
  }, [isSticky, topShiftBlockHeight, headerOnTop]);

  useEffect(() => () => clearLayout(), []);

  return (
    <Helmet>
      {headerTextColor && <style>{`#header[data-hero-colored="true"]{color:${headerTextColor};fill:${headerTextColor}};`}</style>}
    </Helmet>
  );
};

export default memo(HeroEffectLayout);

function clearLayout() {
  const header = document.getElementById('header');
  const layout = document.getElementById('layout');

  if (!header || !layout)
    return;

  delete layout.dataset.heroLayout;
  delete header.dataset.heroColored;
  header.style.top = '';
}
